<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!salesPayment">
      <span class="loader"></span>
    </span>
    <salesPayment-form
      v-if="salesPayment"
      :loading="loading"
      :salesPaymentData="salesPayment"
      :formErrors="formErrors"
      @salesPaymentSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import SalesPaymentForm from "../partials/SalesPaymentForm.vue";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import requestErrorMixin from "@/mixins/request-error-mixin";

export default {
  layout: "DashboardLayout",

  components: { SalesPaymentForm },

  mixins: [alertLeave, requestErrorMixin],

  props: {
    salesPaymentId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      salesPayment: null,
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.get();
  },

  methods: {
    async get() {
      this.loading = true;
      try {
        await this.$store.dispatch("salesPayments/get", this.salesPaymentId);
        this.salesPayment = this.$store.getters["salesPayments/salesPayment"];
        this.loading = false;
      } catch (error) {
        this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.loading = false;
      }
    },

    async handleSubmit(salesPayment) {
      this.loading = true;

      const salesPaymentData = cloneDeep(salesPayment);

      try {
        await this.$store.dispatch("salesPayments/update", salesPaymentData);
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("SALES_PAYMENTS.SALES_PAYMENTS_UPDATED"),
        });
        const salesPayment = await this.$store.getters[
          "salesPayments/salesPayment"
        ];
        this.$emit("onViewSalesPayment", salesPayment);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
